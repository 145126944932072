.ReactModal__Content {
  height: 0;
  transition: height 100ms ease-in-out;
}

.ReactModal__Content--after-open {
  height: 70vh;
}

.ReactModal__Content--before-close {
  height: 0;
}

.ql-editor {
  max-height: 400px;
}

@media only screen and (max-device-width: 500px) {
  .ReactModal__Content {
    transition: none;
  }
  .ReactModal__Content--after-open {
    height: 100vh;
  }
}
.startLink {
  padding: 0 5px;
  cursor: pointer;
  color: #f8492d;
}
